<template>
  <div class="chart-service-container">
    <div class="chart-content">
      <Radio-group v-model="searchOne.time_area" @on-change="changeStatus">
        <Radio label="0">最近一周</Radio>
        <Radio label="1">最近一月</Radio>
        <Radio label="2">最近一个季度</Radio>
        <Radio label="3">最近一年</Radio>
      </Radio-group>
      <div class="chart" ref="barData"></div>
    </div>
    <div class="chart-content">
      <Form :label-width="80" label-position="right"> 
        <Row style="margin-bottom: 18px;">
          <Col span="8">
          <Radio-group v-model="searchTwo.time_area" @on-change="changeStatusTwo">
            <Radio label="0">最近一周</Radio>
            <Radio label="1">最近一月</Radio>
            <Radio label="2">最近一个季度</Radio>
            <Radio label="3">最近一年</Radio>
          </Radio-group>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchTwo.service_pid" placeholder="请选择" @on-change="changeProTwo" v-else>
                <Option :value="v.id" v-for="v in proListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchTwo.service_cid" placeholder="请选择" @on-change="changeCityTwo" v-else>
                <Option :value="v.id" v-for="v in cityListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchTwo.service_sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleTwo()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <div class="chart" ref="barData2"></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import chartService from '@/services/chartService';
import servicesService from '@/services/servicesService';
export default {
	data() {
		return {
			searchOne: {
				time_area: '0',
			},
			searchTwo: {
				time_area: '0',
				service_pid: '',
				service_cid: '',
				service_sid: '',
			},
			barChart: '',
			timesChart: '',
			xArr: [],
			yArr: [],
			proListTwo: [],
			cityListTwo: [],
			cityTwo: {},
			storeListTwo: [],
			storeTwo: {},
			min: '',
			max: '',
			average: '',
			median: '',
			type: '',
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchTwo.service_pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchTwo.service_cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchTwo.service_sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getServicesIndex();
	},
	mounted() {
		// echarts实例化
		this.barChart = echarts.init(this.$refs.barData);
		this.timesChart = echarts.init(this.$refs.barData2);
		// 初始化实例
		let barOptions = {
			title: {
				text: '店面服务次数统计',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		let timesOptions = {
			title: {
				text: '服务项目次数统计',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		this.barChart.setOption(barOptions);
		this.barChart.showLoading();
		this.timesChart.setOption(timesOptions);
		this.getChartServices();
	},
	methods: {
		changeProTwo() {
			this.cityListTwo = this.cityTwo[this.searchTwo.service_pid];
			this.searchTwo.service_cid = '';
		},
		changeCityTwo() {
			this.storeListTwo = this.storeTwo[this.searchTwo.service_cid];
			this.searchTwo.service_sid = '';
		},
		getChartServices() {
			chartService.getChartServices(this.searchOne).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.data_list.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.barChart.hideLoading();
				this.barChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		getServicesIndex() {
			servicesService.indexServices().then((data) => {
				this.proListTwo = data.branch.pro;
				this.cityTwo = data.branch.city;
				this.storeTwo = data.branch.store;
			});
		},
		getChartServicesBranch() {
			chartService.getChartServicesBranch(this.searchTwo).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.data_list.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.timesChart.hideLoading();
				this.timesChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		changeStatus(value) {
			this.searchOne.time_area = value;
			this.getChartServices();
		},
		changeStatusTwo(value) {
			this.searchTwo.time_area = value;
		},
		handleTwo() {
			this.getChartServicesBranch();
		},
	},
};
</script>

<style lang="css" scoped>
</style>
